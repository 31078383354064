<template>
  <div class="wine-content">
    <f7-swiper :slides-per-view="'auto'" :space-between="10">
      <f7-swiper-slide style="width: auto !important" @click="selectVintage(null, $refs)">
        <div class="category" :class="{ active: selectedFilterValue == '' }">
          <div class="category-item">{{ $t.getTranslation("LBL_ALL") }}</div>
        </div>
      </f7-swiper-slide>
      <f7-swiper-slide v-for="vn in vintages" :key="'vn_' + vn.VN" style="width: auto !important" @click="selectVintage(vn.VN, $refs)">
        <div class="category" :class="{ active: selectedFilterValue == vn.VN }">
          <div class="category-item">{{ vn.VN }}</div>
        </div>
      </f7-swiper-slide>
    </f7-swiper>
  </div>
  <div v-for="w in wines" :key="'wine_' + w.FutureWineId" class="wine-grid">
    <div class="wine-info">
      <h4 class="wine-name">{{ w.WineName }}</h4>
      <p class="wine-year">{{ w.Vintage }}</p>
      <p class="wine-year">{{ w.Producer }}</p>
      <p class="wine-year">{{ w.CountryOfOrigin }}</p>
      <p class="wine-year">{{ w.Region }}</p>
    </div>
    <div class="wine-btn">
      <p class="inq-btn" @click="chat()">{{ $t.getTranslation("LBL_INQUIRE") }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

export default defineComponent({
  name: "FutureWineListComponent",
  components: {},
  props: {
    f7router: Object,
    f7route: Object,
  },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;
    const categoryList = ref([]);
    let categoryCode = ref("");
    let page = 1;
    let size = 30;
    let lastPage = 1;
    let wines = ref([]);
    let searchValue = ref("");

    store.dispatch("config/fetchData", { params: { vintageList: 1 } });
    const vintages = computed(() => store.getters["config/getData"]?.vintageList);

    const selectedFilterType = ref("");
    const selectedFilterValue = ref("");

    const getCategoryList = async () => {
      let ret = await get("/category/list", { LanguageCode: $t.getLanguage(), page: 1, size: 1000, mode: "SORTFEATURED" });
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          categoryList.value.push(item);
        }
        categoryList.value.unshift({ CategoryName: $t.getTranslation("LBL_ALL"), CategoryId: 0, Type: "PRODUCT", CategoryCode: "", FieldName: null, FileName: null, Position: null, StatusCode: "ACTIVE" });
      }
    };

    const getList = async () => {
      let ret = await get(`/mobile/product/future/wines/list`, {
        page: page,
        size: size,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value || "",
        FilterType: selectedFilterType.value,
        FilterValue: selectedFilterValue.value,
      });
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          wines.value.push(item);
        }
      }

      lastPage = ret.lastPage;
      page++;
    };

    const selectVintage = (vintage) => {
      if (vintage) {
        selectedFilterValue.value = vintage;
        selectedFilterType.value = "fw.Vintage";
      } else {
        selectedFilterValue.value = "";
        selectedFilterType.value = "";
      }
      wines.value = [];
      page = 1;
      getList();
      return;
    };

    const loadMore = () => {
      if (lastPage >= page) {
        getList();
      }
    };

    const chat = () => {
      $f7router.navigate({ name: "chat", params: {} });
      return;
    };

    onMounted(() => {
      if (props?.f7route?.query?.s) {
        searchValue.value = props.f7route.query.s;
      }
      getList();
      getCategoryList();
    });

    return { wines, loadMore, getList, chat, vintages, selectVintage, selectedFilterValue, selectedFilterType, searchValue };
  },
  methods: {},
});
</script>

<style scoped>
.category.active {
  background: #0c4d8f;
  border-radius: 15px;
  color: #fff;
}

.category-item {
  text-align: center;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-item.active {
  background: #0c4d8f;
  color: #fff;
}

.category {
  display: flex;
  cursor: pointer;
  border-radius: 15px;
  background: #f3f3f3;
}

.wine-content {
  overflow: hidden;
  margin: 15px 0;
}
.wine-info {
  margin: 20px;
  line-height: 15px;
}
.wine-grid {
  position: relative;
  border-left: 12px solid #1a6bd7;
  border-radius: 15px;
  cursor: pointer;
  margin: 0px 0px 20px;
  overflow: hidden;
  background: #f3f3f3;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
}

.wine-btn {
  position: absolute;
  bottom: -15px;
  right: 0;
}
.inq-btn {
  padding: 5px 40px;
  background: #0c4d8f;
  border-radius: 15px 0;
  color: #fff;
  text-transform: uppercase;
}
</style>
